<template>
  <div>
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">1</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные для личного кабинета</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <DealerInfoForUser ref="dealerInfoForUser" />
    </div>
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">2</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Данные Поставщика</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <DealerData ref="dealerDataComponent" />
    </div>
    <div class="divider">
      <div class="ant-steps-item-container"></div>
      <div class="ant-steps-item-icon">
        <span class="ant-steps-icon">3</span>
      </div>
      <div class="ant-steps-item-content">
        <div class="ant-steps-item-title">Контактная информация</div>
      </div>
      <br />
      <br />
    </div>
    <div class="content-container">
      <ContactInfo />
    </div>
    <ButtonsHolder :callBack="handleAcrtionDealerData" />
  </div>
</template>

<script>
import DealerData from "@/components/dealers/DealerData"
import ContactInfo from "@/components/dealers/ContactInfo"
import DealerInfoForUser from "@/components/dealers/DealerInfoForUser"
import ButtonsHolder from "@/components/clients/ButtonsHolder"
import { mapMutations, mapActions } from "vuex"
import { API_PREFIX } from "@/services/api/v1/const"
export default {
  name: "CreateNewDealer",
  mounted() {
    this.setCreateForm({
      name: "",
      paymentGroup: {
        id: "",
        name: "",
        ordering: "",
      },
      headName: "",
      headPosition: "",
      legalInfo: {
        name: "",
        paymentAccount: "",
        bankId: "",
        bankName: "",
        correspondedAccount: "",
        underlyingDocument: "",
        inn: "",
        kpp: "",
        ogrn: "",
        okpo: "",
        address: "",
        physicalAddress: "",
        phone: "",
        site: "",
      },
      contacts: [
        {
          type: "Взаиморасчеты",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Отправка прайс-листов",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Руководитель",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Заказы",
          name: "",
          phone: "",
          email: "",
        },
        {
          type: "Возвраты",
          name: "",
          phone: "",
          email: "",
        },
        /*{
          type: "Обновление паролей",
          name: "",
          phone: "",
          email: "",
        },*/
      ],
    })
  },
  components: {
    DealerData,
    ContactInfo,
    ButtonsHolder,
    DealerInfoForUser,
  },
  methods: {
    ...mapActions({
      createUser: "auth/createUser",
      createDealer: "dealers/createDealer",
    }),
    checkDealer() {
      this.$refs.dealerDataComponent.$v.fields.$touch()
      this.$refs.dealerInfoForUser.$v.fields.$touch()
    },
    handleChange(value) {
      console.log(`selected ${value}`)
    },
    ...mapMutations({
      setCreateForm: "dealers/SET_CREATE_FORM",
    }),
    async handleAcrtionDealerData() {
      this.checkDealer()

      if (
        this.$refs.dealerDataComponent.$v.$error ||
        this.$refs.dealerInfoForUser.$v.$error
      ) {
        window.scrollTo(0, "100%")
      } else {
        await this.addNewDealer()
      }
    },
    async addNewDealer() {
      try {
        console.info("125")
        const [login, password, email] =
          this.$refs.dealerInfoForUser.$v.fields.$model

        let params = {}
        if (API_PREFIX == "https://codex-api.zinvapel.ru") {
          params = {
            login: login.value,
            name: this.$refs.clientDataComponent.$data.fields[0].value,
            email: email.value,
            password: password.value,
            roles: ["ROLE_CLIENT"],
          }
        } else {
          params = {
            name: login.value,
            email: email.value,
            password: password.value,
          }
        }
        const newUserData = params

        const userResp = await this.createUser(newUserData)
        await this.createDealer(userResp)
        this.$router.push(`/dealers`)
      } catch (e) {
        console.info("127")
        if (e && e.statusText) {
          this.$message.error(e.statusText)
        }
      }
    },
  },
}
</script>

<style scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}
#advanced-search .search-result-list {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}
.search-result-text {
  margin: 20px 0 10px 0;
}
.tabsinfo {
  min-height: 570px;
}
.buttonsholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 auto;
}
.selects {
  width: 200px;
}
.optGroupSelect {
  margin-top: 10px;
}
.newClientHeader {
  font-weight: bold;
}
.ant-collapse-header {
  font-size: 16px !important;
  font-weight: 700 !important;
}
</style>
<style lang="scss" scoped>
.divider {
  margin-bottom: -30px;
  &:not(:first-child) {
    margin-top: 30px;
  }
}
</style>
