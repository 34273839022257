<template>
  <div>
    <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 8 }">
      <a-row :gutter="12">
        <a-col
          v-for="v in $v.fields.$each.$iter"
          :key="v.$model.id"
          class="form-item"
        >
          <a-form-item
            :has-feedback="v.value.minLength"
            :validate-status="v.value.$error ? 'error' : ''"
            :label="v.$model.name"
            :help="v.value.$error && 'Необходимо заполнить поле'"
          >
            <a-input
              v-model.trim="v.value.$model"
              :placeholder="v.$model.placeholder"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators"

export default {
  name: "DealerInfoForUser",
  data: () => ({
    fields: [
      {
        id: 1,
        name: "Имя пользователя",
        placeholder: "Введите логин пользователя",
        createclientname: "login",
        value: "",
      },
      {
        id: 2,
        name: "Пароль",
        placeholder: "Введите пароль",
        createclientname: "password",
        value: "",
      },
      {
        id: 3,
        name: "Почта",
        placeholder: "Введите почту",
        createclientname: "mail",
        value: "",
      },
    ],
  }),
  validations: {
    fields: {
      required,
      $each: {
        value: {
          required,
        },
      },
    },
  },
}
</script>

<style scoped></style>
